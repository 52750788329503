<template>
  <div class="flex flex-col flex-1">
    <div class="flex justify-between">
      <Text v-if="label" weight="semibold" size="sm" color="gray-700" custom-class="block" :content="label" />
      <Text v-if="hint" :id="`${id}-optional`" size="sm" color="gray-500" :content="hint" />
    </div>
    <div :class="(label || hint) && 'mt-1'" class="relative w-full flex">
      <div v-if="addon"
        class="h-10 px-3 flex justify-center items-center bg-gray-100 border rounded-l-md border-gray-300">
        <Text size="sm" color="black" :content="addon" />
      </div>
      <input v-if="!isError" :id="id" v-mask="inputMask" autocomplete="off" :value="inputValue" :type="type" :name="name" :class="
        `${customClass} ${addon ? 'rounded-r-md border-r border-t border-b' : 'rounded-l-md border-l border-t border-b'}
                                                                                  appearance-none
                                                                                  block
                                                                                  w-full
                                                                                  px-3
                                                                                  py-2
                                                                                  h-10
                                                                                   border-gray-300
                                                                          
                                                                                  placeholder-gray-400
                                                                                  focus:outline-none focus:shadow-outline-blue focus:border-primary-300
                                                                                  transition
                                                                                  duration-150
                                                                                  ease-in-out
                                                                                  ${isButton ? 'pr-9' : ''}
                                                                                  sm:text-sm sm:leading-5`
      " :placeholder="placeholder" @keyup.enter="() => $emit('onPressEnter')"
        @input="$emit('update:value', $event.target.value)" @focus="$emit('focus')" @blur="$emit('blur')">
      <input v-else  autocomplete="off" :id="id" v-mask="inputMask" :value="inputValue" :class="customClass" :type="type" :name="name"
        class="block w-full px-3 py-2 pr-10 text-red-900 placeholder-red-300 border border-red-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
        :placeholder="placeholder" aria-invalid="true" :aria-describedby="`${name}-error`"
        @keyup.enter="() => $emit('onPressEnter')" @input="$emit('update:value', $event.target.value)"
        @focus="$emit('focus')" @blur="$emit('blur')">
      <!-- <div v-if="isError" class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none ">
        <Icons name="ExclamationCircleIcon" size="5" color="red-500" />
      </div> -->
      <div v-if="leading" class="h-10 px-3 flex justify-center items-center bg-gray-100 border rounded-r-md border-gray-300">
        <slot name="addon" />
      </div>

    </div>
    <Text :id="`${name}-description`" size="xs" weight="normal" :content="helpText" custom-class="italic mt-1"
      :color="isError ? 'red-600' : 'gray-400'" />
  </div>
</template>

<script>
import Icons from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import { mask } from "vue-the-mask";
/**
 * - Use an input to fill out the form with special label, hint, help text
 * - value param is required. and the other params are optional
 */
export default {
  components: {
    Icons,
    Text,
  },
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
  },
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true
    },
    /**
     * Use to identify the input form
     */
    id: {
      type: String,
      default: "",
    },
    /**
     * Use to identify the input form. same with id. but it could be a special word or something like that
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * Use to show what input form is
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of input form
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use to hint users for input value
     */
    hint: {
      type: String,
      default: "",
    },
    /**
     * Use to help users to fill form out
     */
    helpText: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic form value
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Use to apply extra class for input form
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to specify input type. ex: text, email, number. default will be text.
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Use to speciy the input value's format. ex: (###) ###-#### for phone number
     */
    inputMask: {
      type: String,
      default: "",
    },
    /**
     * Use to show whether or not value is valid.
     */
    isError: {
      type: Boolean,
      default: false,
    },
    /**
     * function called on pressing enter
     */
    onEnterPressed: {
      type: Function,
      default: () => { }
    },
    addon: {
      type: String,
      default: ''
    },
    leading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    inputValue: {
      // getter
      get() {
        return this.value;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
