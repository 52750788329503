<template>
  <div class="flex flex-col space-y-6">
    <CheckboxList v-model:selected="selectedItems" :items="options" />
    <InputWithButton id="add_input" v-model:value="inputText" type="text"
      :placeholder="placeholder || 'Please Input a value'" btnText="Add" :btnClick="addClicked" class="w-full md:w-1/2" />
  </div>
</template>
<script>
import { computed, ref } from "vue";
import CheckboxList from '../SimpleList/SimpleList.vue';
import InputWithButton from '../../Inputs/InputWithLeadingIconAndTrailingButton/InputWithLeadingIconAndTrailingButton.vue'
export default {
  components: {
    CheckboxList,
    InputWithButton
  },
  props: {
    /**
    list of items
    */
    items: {
      type: Array,
      default: () => [],
    },
    /**
    state of checkbox.
    */
    selected: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: ''
    },
    onAdd: {
      type: Function,
      default: () => { }
    }
  },
  setup(props, { emit }) {
    const options = ref(props.items);
    const inputText = ref();
    const selectedItems = computed({
      get() {
        return props.selected;
      },
      set(newValue) {
        emit("update:selected", newValue);
      },
    });
    const addClicked = () => {
      if (inputText.value) {
        if (props.onAdd) {
          props.onAdd(inputText.value);
        } else {
          if (options.value.indexOf(inputText.value) === -1) {
            options.value.push(inputText.value);
            emit("update:selected", [...selectedItems.value, inputText.value]);
          }
        }
        inputText.value = null;
      }
    }

    return {
      selectedItems,
      options,
      inputText,
      addClicked
    }
  }
}
</script>