<template>
  <SideOver ref="sideContainer" v-if="isLoad" isLarge :title="title" :is-show="isShow" @onClose="() => onClose()" :label="content"
    :isLoading="isLoading" :buttons="getButtons()">
    <div class="flex-1 flex-col p-6 space-y-4 h-full overflow-y-auto">
      <SurveyForm :ref="(el) => assignRef(el)" :list="list" :title="''" :description="''" class="w-full"
        titleTextSize="lg" titleTextColor="black" containerSpacing="space-y-3" bodySpacing="space-y-3"
        fieldSpacingClass="pt-4" :showMandatoryLabel="false" :modelKey="modelKey" :orig-form-data="savedFormData"
        @on-validate="onValidate" :isHelp="isHelp"/>
    </div>
  </SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'
import AutoComplete from '../../../molecules/AutoComplete/AutoComplete'
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import Simple from '../../../organisms/Tables/Simple/Simple.vue';
import { ref, watch, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Controller, Virtual } from 'swiper';
import 'swiper/swiper.min.css';
import SurveyForm from '../../SurveyForm/SurveyForm.vue'

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver,
    Textarea,
    Swiper,
    SwiperSlide,
    SurveyForm,
    AutoComplete,
    MultiSelectDropdown,
    Simple
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    modelKey: {
      type: String,
      default: 'id'
    },
    formData: {
      type: Object,
      default: () => { }
    },
    saveForm: {
      type: Function,
      default: () => {}
    },
    isHelp: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    console.log(props, 'props')
    const formRefs = ref({});
    const isValid = ref(false);
    let savedFormData = ref(props.formData || {})
    const isLoad = ref(false)
    const onClose = () => {
      emit('onClose');
      setTimeout(() => (isLoad.value = false), 500);
    }
    const getButtons = () => {
      return [
        {
          label: 'Cancel',
          type: 'secondary',
          isLoading: false,
          action: () => onClose()
        },
        {
          label: 'Save',
          type: 'primary',
          isLoading: props.isSaving,
          isDisabled: !isValid.value,
          action: async () => await onSave()
        }
      ]
    }
    const onSave = async () => {
      let valid = formRefs.value.checkValidForm()
      onValidate(valid);
      savedFormData = formRefs.value.getFormObject();
      // emit('saveForm', savedFormData);
      await props.saveForm(savedFormData);
    }

    const onValidate = (val) => {
      isValid.value = !val;
    }

    const assignRef = (el) => {
      formRefs.value = el;
    }
    return {
      onClose,
      getButtons,
      onSave,
      formRefs,
      onValidate,
      assignRef,
      savedFormData,
      isLoad,
    }
  },
  watch: {
    isShow: function (val) {
      if (val) {
        setTimeout(() => (this.isLoad = true), 100);
      } else {
        setTimeout(() => (this.isLoad = false), 700);
      }
    },
  },
}
</script>
<style scoped>
.swiper {
  height: 100%
}
</style>
<style>
.select-inline {
  display: flex;
  align-items: center;
}

.select-inline label {
  margin-bottom: 0 !important;
  margin-right: 20px;
  font-weight: 500 !important;
}

.select-inline div {
  flex: 1
}
</style>
