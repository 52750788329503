<template>
  <div class="w-full px-7 py-5 relative flex flex-col justify-center items-center">
    <VueSlider v-model="selectedItems" :marks="marks" :max="max" :min="min" contained :order="false"
      :tooltip-style="tooltipStyle" :rail-style="railStyle" class="w-full" @drag-end="onDragEnd"
      @drag-start="() => {isDragged = true; $emit('onDragged', true)}" :process-style="selectedItems[1] > 0 ? processStyle : process1Style"
      :dot-options="dotOptions">
      <template v-slot:mark="{ pos, label, active, value }">
        <div :class="`custom-mark ${active ? (value >= 0 ? 'custom-mark-active' : 'custom1-mark-active') : ''}`"
          :style="{ left: `${pos}%` }">
          <div class="custom-label">{{ label }}</div>
        </div>
      </template>
      <template v-slot:tooltip="{ value, focus }">
        <div :class="['custom-tooltip', { focus }]" v-if="value !== 0">{{ value }}</div>
        <div :class="['bottom-tooltip', { focus }]" v-if="value !== 0">{{ marks[value.toString()] &&
          marks[value.toString()].tooltip }}</div>
        <div v-else />
      </template>
      <template v-slot:dot="{ focus, value }">
        <div :class="[value > 0 ? 'custom-dot' : value < 0 ? 'custom1-dot' : 'static-dot', { focus }]"></div>
      </template>
    </VueSlider>
    <!-- <VueSlider v-else v-model="selectedItems" :marks="marks" :max="max" :min="min" @drag-end="onDragEnd"
      @drag-start="() => isDragged = true" :process-style="selectedItems[1] > 0 ? processStyle : process1Style"
      :order="false" :dot-options="dotOptions" contained :rail-style="railStyleVert" direction="ttb"
      style="display: inline-block; margin: 30px 14px; height: 400px;">
      <template v-slot:mark="{ pos, label, active, value }">
        <div
          :class="`custom-mark-vert ${active ? (value >= 0 ? 'custom-mark-vert-active' : 'custom1-mark-vert-active') : ''}`"
          :style="{ top: `${pos}%` }">
          <div class="custom-label-vert">{{ label }}</div>
        </div>
      </template>
      <template v-slot:tooltip="{ value, focus }">
        <div :class="['custom-tooltip', { focus }]" v-if="value !== 0">{{ value }}</div>
        <div :class="['right-tooltip', { focus }]" v-if="value !== 0">{{ marks[value.toString()] &&
          marks[value.toString()].tooltip }}</div>
        <div v-else />
      </template>
      <template v-slot:dot="{ focus }">
        <div :class="[value > 0 ? 'custom-dot' : value < 0 ? 'custom1-dot' : 'static-dot', { focus }]"></div>
      </template>
    </VueSlider> -->
    <div v-if="!isDragged"
      class="absolute z-50 ml-0 top-14 shadow-lg rounded px-1 py-1 bg-indigo-500 text-xs text-semibold text-white">
      Drag to
      Start
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  components: {
    VueSlider
  },
  props: {
    marks: {
      type: Object,
      default: () => { }
    },
    min: {
      type: Number,
      default: '0'
    },
    max: {
      type: Number,
      default: '100'
    },
    selected: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      dotOptions: [
        { disabled: true },
        { disabled: false }
      ]
    }
  },
  methods: {
    onDragEnd() {
      // this.dotOptions = [
      //   { disabled: true },
      //   { disabled: true }
      // ]
    }
  },
  setup(props, { emit }) {
    const windowWidth = ref(window.innerWidth);
    const selectedItems = computed({
      get() {
        return props.selected || [props.min, props.max];
      },
      set(newValue) {
        emit("update:selected", newValue);
      },
    })
    if (selectedItems.value.length) {
      emit("update:selected", selectedItems.value);
    }
    const isDragged = ref(false)
    const railStyle = ref({
      backgroundColor: 'rgb(209, 213, 219)',
      height: '8px'
    });
    const railStyleVert = ref({
      backgroundColor: 'rgb(209, 213, 219)',
      width: '8px'
    });
    const tooltipStyle = ref({
      backgroundColor: 'gray',
      color: 'black'
    })
    const processStyle = ref({
      backgroundColor: '#574CCB',
    });
    const process1Style = ref({
      backgroundColor: '#C547E8',
    });

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })
    // const process1 = (dotsPos) => {
    //   return [
    //       [props.min, dotsPos[0], { backgroundColor: 'pink' }],
    //       [dotsPos[0], props.max, { backgroundColor: 'blue' }]
    //   ];
    // };
    return {
      //process1,
      railStyle,
      railStyleVert,
      tooltipStyle,
      processStyle,
      process1Style,
      selectedItems,
      windowWidth,
      isDragged
    }
  },
}
</script>
<style>
.custom-mark {
  background: gray;
  height: 12px;
  width: 2px;
  top: 16px;
  position: absolute;
  transform: translateX(-50%);
}

.custom-mark-active {
  background: #574CCB;
}

.custom1-mark-active {
  background: #C547E8;
}

.custom-mark-vert {
  background: gray;
  width: 12px;
  height: 2px;
  left: 20px;
  position: absolute;
  transform: translate(-50%, -50%)
}

.custom-mark-vert-active {
  background: #574CCB;
}

.custom1-mark-vert {
  background: gray;
  width: 12px;
  height: 2px;
  left: 20px;
  position: absolute;
  transform: translate(-50%, -50%)
}

.custom1-mark-vert-active {
  background: #C547E8;
}

.custom-label {
  font-size: 12px;
  padding-top: 16px;
  width: 80px;
  text-align: center;
  position: absolute;
  right: -40px;
  color: #6d6b7b;
}

.custom-label-vert {
  font-size: 12px;
  width: 80px;
  text-align: center;
  position: absolute;
  left: 18px;
  top: -8px;
}

.custom-label-active {
  background-color: gray;
  color: white;
}

.custom-tooltip {
  background-color: rgb(243 244 246);
  color: black;
  padding: 4px 8px;
  margin-bottom: 4px;
  font-size: 12px;
  top: 12px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  transform: translateY(5px);
}

.custom-tooltip.focus {
  font-weight: 700;
}

.bottom-tooltip {
  background-color: rgb(49, 51, 54);
  color: white;
  padding: 3px 5px;
  margin-top: 35px;
  margin-bottom: -65px;
  font-size: 12px;
  top: 24px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  transform: translateY(5px);
  white-space: nowrap;
}

.right-tooltip {
  background-color: rgb(49, 51, 54);
  color: white;
  padding: 5px 8px;
  font-size: 12px;
  top: 24px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  transform: translateY(5px);

  white-space: nowrap;
}

.custom-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #574CCB;
  background-color: white;
  transition: all .3s;
}

.static-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: white;
  transition: all .3s;
}

.custom1-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #C547E8;
  background-color: white;
  transition: all .3s;
}

.custom-dot:hover {
  transform: rotateZ(45deg);
  background-color: #574CCB;
}

.custom-dot.focus {
  transform: rotateZ(45deg);
  background-color: #574CCB;
}

.custom1-dot:hover {
  transform: rotateZ(45deg);
  background-color: #C547E8;
}

.custom1-dot.focus {
  transform: rotateZ(45deg);
  background-color: #C547E8;
}

.vue-slider-dot-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* @media only screen and (max-width: 600px) {
  .vue-slider-dot-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 7px;
  }

  .custom-tooltip {
    background-color: rgb(243 244 246);
    color: black;
    padding: 4px 8px;
    margin-bottom: 0px;
    font-size: 12px;
    top: 12px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    transform: translateY(5px);
  }
} */
</style>