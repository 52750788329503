<template>
  <div class="w-full relatie" :class="isFull ? '' : 'max-w-md'" v-click-outside="() => isOpen = false">
    <Text size="sm" weight="semibold" :content="label" />
    <div class="relative flex flex-col justify-start items-start">
      <div class="z-0 flex justify-between items-center shadow-sm w-full border rounded-md hover:border-primary-600 mt-1"
        style="min-height: 40px;">
        <div v-if="addon" class="h-full flex-1 px-3 flex justify-center items-center bg-gray-100 border-r rounded-l-md"
          style="min-height: 40px;">
          <Text size="sm" color="black" :content="addon" />
        </div>
        <div class="flex flex-wrap flex-shrink w-full py-1 px-2 bg-white space-x-1">
          <div v-for="item in selectedItems" :key="item"
            class="flex items-center justify-center h-full px-2 py-1 text-xs text-white bg-primary-600 rounded">
            {{ isObj ? (item && item.name) : item }}
            <Icon v-if="!disabled" @click.stop="onRemove(item)" name="XIcon" size="3" color="white"
              customClass="ml-1 cursor-pointer" />
          </div>
          <input v-if="get(selectedItems, 'length') < limit && !disabled" @focus="isOpen = true" v-model="keyword" @keyup.enter="() => !isNotAdd ? onAdd(): {}"
            class="flex flex-1 w-full outline-none border-none ring-0 focus:outline-none focus:border-none focus:ring-0 mx-1 text-sm"
            :class="inputClass" autocomplete="off" :placeholder="get(selectedItems, 'length') > 0 ? '' : placeholder"
            style="min-width: 40px;" />
        </div>
        <Icon v-if="!disabled" :name="'SelectorIcon'" color="gray-400" size="5" custom-class="mx-2" />
      </div>
      <div class="relative w-full">
        <div v-if="isOpen" class="z-30 bg-white border absolute left-0 right-0 overflow-y-scroll py-1 rounded-md"
          style="max-height: 300px;">
          <a v-for="(item, id) in filterItems()" :key="id" @click="onSelect(item)"
            :class="(keyword && id === 0) ? 'bg-gray-100' : ''"
            class="flex items-center w-full px-2 py-2 text-sm cursor-pointer hover:bg-primary-600 hover:text-white">
            {{ isObj ? (item && item.name) : item }}
          </a>
          <div v-if="isNotAdd && filterItems().length === 0"
            class="flex items-center w-full px-2 py-1 text-sm font-medium text-gray-400">
            Please type or select a valid selection
          </div>
          <div v-if="!isNotAdd" class="mx-2 bg-gray-200" style="height: 1px;"></div>
          <div v-if="!isNotAdd" class="flex justify-between items-center w-full px-2 py-2 space-x-2">
            <a class="flex flex-1 text-sm text-green-500 hover:bg-primary-600 hover:text-white" @click="onAdd"
              :class="keyword && filterItems().length === 0 && 'bg-gray-100'">
              + Add {{ keyword && keyword.length > 0 ? `"${keyword}""` : 'New' }}
            </a>
            <Radio class="-mt-4" v-if="get(radioOptions, 'length') > 0" inline is-simple-list :items="radioOptions"
              v-model:value="selectedRadio" />
          </div>
        </div>
      </div>
      <Text size="xs" weight="" color="gray-400" custom-class="mt-1 italic" :content="description" />
    </div>
  </div>
</template>

<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Text from '../../../atoms/Text/Text.vue'
import vClickOutside from 'click-outside-vue3'
import Radio from "../../../molecules/RadioGroup/Simple/Simple";
import { json } from 'overmind'
import { get } from 'lodash'

export default {
  components: {
    Radio,
    Icon,
    Text
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    selected: {
      type: Array,
      default: () => []
    },
    isNotAdd: {
      type: Boolean,
      default: false,
    },
    isObj: {
      type: Boolean,
      default: false,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: ''
    },
    radioOptions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    isLocation: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 10000,
    },
    isExternalAdd: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: ''
    },
    addon: {
      type: String,
      default: null,
    }
  },
  methods: {
    onClick() {
      if (this.disabled?.length === 0 || !this.disabled) {
        this.isOpen = !this.isOpen
      }
    },
    onAdd() {
      if (this.isExternalAdd) {
        this.isOpen = false;
        this.$emit('onAdd', this.keyword);
        this.keyword = null
        return false;
      }
      if (this.radioOptions?.length > 0 && !this.selectedRadio) {
        return;
      }
      if (this.keyword) {
        this.selectedItems.push(this.isObj ? { id: Math.random(), name: this.keyword, selectedOption: this.selectedRadio } : this.keyword);
        this.updateSelected();
        this.items.push(this.isObj ? { id: Math.random(), name: this.keyword, selectedOption: this.selectedRadio } : this.keyword);
        this.keyword = null;
      } else {
        this.$refs.keyword.focus();
      }
    },
    onSelect(item) {
      this.selectedItems.push(item);
      this.updateSelected();
      this.keyword = null;
      this.isOpen = false;
    },
    filterItems() {
      const items = json(this.items)?.filter(
        (item) => !this.selectedItems.find((s) => {
          return this.isObj ? (JSON.stringify(s) === JSON.stringify(item)) : s === item
        })
      );
      if (this.keyword && items?.length > 0) {
        return items.filter((i) => (i?.name || i || '')?.toLowerCase()?.includes(this.keyword?.toLowerCase())) || [];
      } else {
        return items || [];
      }
    },
    onRemove(item) {
      const index = this.selectedItems.findIndex((s) => {
        return this.isObj ? s?.id === item?.id : s === item
      });
      if (index > -1) {
        this.selectedItems.splice(index, 1);
        this.updateSelected();
      }
    },
    handleAdding() {
      if (this.keyword?.length > 0 && this.filterItems().length === 0) {
        const item = this.isObj ? { id: Math.random(), name: this.keyword } : this.keyword
        this.selectedItems.push(item);
        this.updateSelected();
        this.items.push(item);
        this.keyword = null;
      } else if (this.keyword?.length > 0 && this.filterItems().length > 0) {
        const item = this.filterItems()[0];
        this.selectedItems.push(item);
        this.updateSelected();
        this.items.push(item);
        this.keyword = null;
      }
    },
    updateSelected() {
      this.$emit("update:selected", this.selectedItems);
    },
  },
  data() {
    return {
      items: this.options,
      selectedItems: this.selected,
      keyword: null,
      isOpen: false,
      selectedRadio: null,
      get,
    }
  },
  watch: {
    options: function (val) {
      this.items = this.options;
    },
    selected: function (val) {
      this.selectedItems = val;
    },
    keyword: function (val) {
      if (this.isLocation) {
        this.$emit('onSearch', val)
      }
    }
  }
}
</script>
