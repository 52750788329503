
<template>
  <fieldset class="space-y-4">
    <div v-for="(item, index) in items" :key="index" class="relative flex items-start">
      <div class="flex items-center h-5">
        <input :id="`item-${index}`" v-model="selectedItems" :value="item" aria-describedby="comments-description"
          :name="`item-${index}`" type="checkbox"
          class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded">
      </div>
      <div class="ml-3">
        <Text :for="`item-${index}`" size="sm" weight="normal" color="graytext"
          :content="item.name || item.answer || item" />
      </div>
    </div>
  </fieldset>
</template>

<script>
/**
- Checkbox List with text
- options to  title,description
**/
import Text from "../../../atoms/Text/Text.vue";
export default {
  components: {
    Text,
  },
  props: {
    /**
    list of items
    */
    items: {
      type: Array,
      default: () => [],
    },
    /**
    state of checkbox.
    */
    selected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.selected;
      },
      set(newValue) {
        console.log(newValue, 'value')
        this.$emit("update:selected", newValue);
      },
    },
  },
};
</script>
