<template>
  <div class="flex flex-col space-y-6">
    <div class="flex items-center space-x-2">
      <!-- <Text size="sm" weight="semibold" color="gray-600" content="They" /> -->
      <Input :placeholder="placeholder || 'Please input a value'" v-model:value="inputText"
        @onPressEnter="() => addClicked()" addon="They"/>
    </div>

    <Text @click="() => addClicked()" class="underline cursor-pointer mr-5" :content="'Save and Add'" color="green-500"
      hoverColor="green-600" size="sm" weight="medium" />
    <Table :list="options" v-if="options?.length > 0" class="flex h-auto overflow-x-auto sm:block" :displayHeaders="false" :displayTableOnly="true">
      <template v-slot:multiselect="{ id, selectedItem, setSelectedItem }">
        <div class="w-full flex justify-center items-center h-full bg-white">
          <Text @click="() => { editItem(id) }" class="underline cursor-pointer mr-5" :content="'edit'" color="gray-500"
            hoverColor="gray-600" size="sm" weight="medium" />
          <Text @click="() => { removeItem(id) }" class="underline cursor-pointer" :content="'remove'" color="red-500"
            hoverColor="red-600" size="sm" weight="medium" />
        </div>
      </template>
    </Table>
    <DeleteAlert :isShow="showDeleteDialog" description="Do you really want to delete this item?" icon='ExclamationIcon'
      yesBtnText='Delete' :onYesClick="() => yesClicked()" noBtnText='Cancel' :onNoClick="() => noClicked()"
      yesBtnVariant='danger' noBtnVariant='secondary' iconColor='red'></DeleteAlert>
  </div>
</template>
<script>
import { ref } from "vue";
import Table from '../Simple/Simple.vue'
import Text from '../../../atoms/Text/Text.vue'
import Input from '../../../molecules/Inputs/WithAddon/WithAddon.vue'
import DeleteAlert from '../../Modals/SimpleAlert/SimpleAlert.vue'

export default {
  components: {
    Text,
    Table,
    Input,
    DeleteAlert
  },
  props: {
    /**
list of items
*/
    items: {
      type: Array,
      default: () => [],
    },
    objKey: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      default: ''
    }

  },
  setup(props, { emit }) {
    const options = ref(props.items);
    const inputText = ref();
    let selectedId = null;
    const showDeleteDialog = ref(false);
    const addClicked = () => {
      if (inputText.value) {
        console.log(selectedId, 'selectedId')
        if (selectedId !== null) {
          const obj = options.value[selectedId];
          obj[props.objKey] = inputText.value;
          emit("save", options);
          inputText.value = null;
          
        } else {
          options.value.push({ [props.objKey]: inputText.value });
          console.log(options.value, 'options')
          emit("save", options);
          inputText.value = null;
        }
      }
    }
    const removeItem = (item) => {
      console.log(item);
      selectedId = item;
      showDeleteDialog.value = true;
    }

    const editItem = (item) => {
      const obj = options.value[item];
      console.log(obj, 'obj', props.objKey)
      if (obj) {
        inputText.value = obj[props.objKey];
        console.log(inputText.value)
        selectedId = item;
      }
    }

    const yesClicked = () => {
      let deletedItem = options.value.splice(selectedId, 1);
      emit('removeItem', options);
      showDeleteDialog.value = false;
      selectedId = null;
    }

    const noClicked = () => {
      selectedId = null;
      showDeleteDialog.value = false;
    }

    return {
      options,
      inputText,
      addClicked,
      removeItem,
      editItem,
      showDeleteDialog,
      yesClicked,
      noClicked
    }
  }
}
</script>
