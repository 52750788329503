<template>
    <div :class="containerSpacing" class="flex flex-col justify-between flex-1">
        <div :class="bodySpacing">
            <Text
                v-if="title"
                :content="title"
                :size="titleTextSize"
                weight="bold"
                :color="titleTextColor"
                align="left"
                custom-class="leading-normal"
            />
            <Text
                v-if="description"
                v-html="getDescription(description)"
                size="base"
                weight="medium"
                color="gray-600"
                align="left"
                custom-class="leading-normal italic mt-2"
            />
            <div>
                <div
                    v-for="item in getFiltered(list)"
                    :key="item.id"
                    :class="`flex flex-col ${fieldSpacingClass}`"
                >
                    <Text
                        v-if="item.groupFields"
                        :content="
                            item.isRequired ? item.label + ' *' : item.label
                        "
                        size="base"
                        weight="semibold"
                        custom-class="pb-4"
                        color="graytext"
                    />
                    <div
                        class="pb-6"
                        v-for="field in item.fields"
                        :key="field.id"
                        :class="
                            field.isLeft
                                ? 'flex justify-between items-center'
                                : ''
                        "
                    >
                        <!-- <Text v-if="field.isLeft" :content="field.label" size="base" weight="semibold" custom-class="pr-4"
                            color="graytext" /> -->
                        <template v-if="field.inputType === FIELD_TYPES.TEXT">
                            <TextInput
                                :id="field.id"
                                v-model:value="formObject[field[modelKey]]"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? getFieldLabel(field.label)
                                        : ''
                                }`"
                                type="text"
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Please input a value'
                                }`"
                                :input-mask="field.mask"
                                @focus="
                                    field[modelKey] === 'name'
                                        ? (isFocusName = true)
                                        : ''
                                "
                                @blur="
                                    field[modelKey] === 'name'
                                        ? (isFocusName = false)
                                        : ''
                                "
                            />
                        </template>
                        <template
                            v-if="
                                field.inputType === FIELD_TYPES.TEXT_WITH_ADDON
                            "
                        >
                            <TextInputWithAddon
                                :id="field.id"
                                v-model:value="formObject[field[modelKey]]"
                                :addon="field.addon"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                type="text"
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Please input a value'
                                }`"
                                :input-mask="field.mask"
                                @focus="
                                    field[modelKey] === 'name'
                                        ? (isFocusName = true)
                                        : ''
                                "
                                @blur="
                                    field[modelKey] === 'name'
                                        ? (isFocusName = false)
                                        : ''
                                "
                            />
                        </template>
                        <template v-if="field.inputType === FIELD_TYPES.DATE">
                            <TextInput
                                :id="field.id"
                                v-model:value="formObject[field[modelKey]]"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                type="date"
                                :placeholder="`${
                                    field.placeholder ? field.placeholder : ''
                                }`"
                                :input-mask="field.mask"
                            />
                        </template>
                        <template
                            v-if="
                                field.inputType === FIELD_TYPES.TEXT_WITH_BUTTON
                            "
                        >
                            <InputWithButton
                                :id="field.id"
                                v-model:value="formObject[field[modelKey]]"
                                type="text"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                placeholder="Please Input a value"
                                :btnText="field.buttonLabel"
                                :btnClick="addOnBtnClicked"
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.SELECT"
                        >
                            <Select
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                v-model:selected-item="
                                    formObject[field[modelKey]]
                                "
                                :items="JSON.parse(field.options)"
                                is-simple-list
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.TEXT_AREA
                            "
                        >
                            <TextArea
                                :isHtml="field.isHtml"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                v-model:value="formObject[field[modelKey]]"
                                :placeholder="field.placeholder || 'Type here'"
                                custom-class="mr-1  border border-gray-300"
                                @focus="
                                    field[modelKey] === 'description'
                                        ? (isFocusDescription = true)
                                        : ''
                                "
                                @blur="
                                    field[modelKey] === 'description'
                                        ? (isFocusDescription = false)
                                        : ''
                                "
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.HEADER"
                        >
                            <Text
                                v-if="field.label"
                                :content="field.label"
                                :size="titleTextSize"
                                weight="bold"
                                :color="titleTextColor"
                                align="left"
                                custom-class="leading-normal mt-5"
                            />
                            <Text
                                v-if="field.content"
                                :content="field.content"
                                size="xs"
                                weight="medium"
                                color="gray-400"
                                align="left"
                                custom-class="leading-normal italic mt-2 -mb-3"
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.RADIO"
                        >
                            <SimpleRadioGroup
                                v-model:value="formObject[field[modelKey]]"
                                :items="JSON.parse(field.options)"
                                :inline="showInlineRadioGroup"
                                :id="field.id"
                                is-simple-list
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType ===
                                FIELD_TYPES.CHECKBOX_WITH_INPUT
                            "
                        >
                            <CheckboxListWithInput
                                v-model:selected="formObject[field[modelKey]]"
                                :items="JSON.parse(field.options)"
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.CHECKBOX"
                        >
                            <CheckboxList
                                v-model:selected="formObject[field[modelKey]]"
                                :items="JSON.parse(field.options)"
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.TAGS"
                        >
                            <MultiSelect
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                v-model:selected="formObject[field[modelKey]]"
                                :options="JSON.parse(field.options)"
                                :is-obj="field.isObject"
                                :is-full="true"
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Start typing to filter'
                                }`"
                                :is-not-add="field.isNotAdd"
                                :radioOptions="
                                    field.label === 'Interests and Activities'
                                        ? ['Interest', 'Activity']
                                        : null
                                "
                                :description="field.helpText"
                                :limit="field.limit || 10000"
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.TAGS_WITH_ADDON
                            "
                        >
                            <MultiSelect
                                v-model:selected="formObject[field[modelKey]]"
                                :addon="field && field.addon"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                :options="JSON.parse(field.options)"
                                :is-obj="field.isObject"
                                :is-full="true"
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Start typing to filter'
                                }`"
                                :is-not-add="field.isNotAdd"
                                :description="field.helpText"
                                :limit="field.limit || 10000"
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.LOCATION_TAGS
                            "
                        >
                            <MultiSelect
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                v-model:selected="formObject[field[modelKey]]"
                                :options="[...json(state.google.placeResults)]"
                                :is-obj="field.isObject"
                                :is-full="true"
                                :is-not-add="true"
                                :description="field.helpText"
                                :placeholder="field.placeholder"
                                isLocation
                                @onSearch="searchGooglePlaces"
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.BOOLEAN_SELECT
                            "
                        >
                            <Select
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                v-model:selected-item="
                                    booleanObject[field[modelKey]]
                                "
                                :items="booleanSelectOptions"
                                :selection-changed="selectionChanged(field.id)"
                            />
                        </template>
                        <template
                            v-else-if="field.inputType === FIELD_TYPES.DROPDOWN"
                        >
                            <Dropdown
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Please select a value'
                                }`"
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                :items="JSON.parse(field.options)"
                                v-model:selected-item="
                                    formObject[field[modelKey]]
                                "
                                class="flex flex-1 flex-col"
                                :help-text="field.helpText"
                                isClear
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType ===
                                FIELD_TYPES.DROPDOWN_WITH_ADDON
                            "
                        >
                            <Dropdown
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Please select a value'
                                }`"
                                :addon="field && field.addon"
                                :items="JSON.parse(field.options)"
                                v-model:selected-item="
                                    formObject[field[modelKey]]
                                "
                                class="flex flex-1 flex-col"
                                :help-text="field.helpText"
                                isClear
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.RANGE_SLIDER
                            "
                        >
                            <Slider
                                v-model:selected="formObject[field[modelKey]]"
                                :marks="field.marks"
                                :min="field.min"
                                :max="field.max"
                                class="mb-12"
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.AUTOCOMPLETE
                            "
                        >
                            <AutoComplete
                                :label="`${
                                    !field.isLeft &&
                                    !item.groupFields &&
                                    field.label
                                        ? field.label
                                        : ''
                                }`"
                                :listData="JSON.parse(field.options)"
                                :isNotMark="field.isNotMark"
                                :placeholder="`${
                                    field.placeholder
                                        ? field.placeholder
                                        : 'Start typing to filter'
                                }`"
                                v-model:selectedValue="
                                    formObject[field[modelKey]]
                                "
                                :helpText="field.helpText"
                            />
                        </template>
                        <template
                            v-else-if="
                                field.inputType === FIELD_TYPES.TABLE_WITH_INPUT
                            "
                        >
                            <TableWithInput
                                :items="JSON.parse(field.options)"
                                :objKey="field.key"
                                :placeholder="field.placeholder"
                                @save="
                                    (items) =>
                                        saveTableItem(
                                            items,
                                            field.options,
                                            field.modelField
                                        )
                                "
                                @removeItem="
                                    (item) =>
                                        removeTableItem(
                                            item,
                                            field.options,
                                            field.modelField
                                        )
                                "
                            />
                        </template>
                    </div>
                </div>
            </div>
            <Text
                v-if="showMandatoryLabel"
                content="* Mandatory Fields"
                size="base"
                color="gray-800"
                align="left"
                class="pt-6"
            ></Text>
        </div>
        <div class="mt-6">
            <div
                v-if="
                    (id === '1' && formObject.description) ||
                    (id === '2' && get(formObject, 'help')) ||
                    (id === '4' && get(formObject, 'reasonSecond.label')) ||
                    (id === '5' && get(formObject, 'solutions.length') > 0) ||
                    isHelp
                "
                class="bg-primary-700 px-6 py-4 w-full"
            >
                <Text
                    size="sm"
                    weight="semibold"
                    color="white"
                    :content="
                        isHelp
                            ? 'Does this Opportunity Statement sound right?'
                            : 'Does this sound right?'
                    "
                />
                <Text
                    size="xs"
                    weight=""
                    color="white"
                    v-html="getHtml()"
                    custom-class="mt-2"
                />
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Dynamic Form
 * Options for Select, RadioGroup,Input, TextArea
 * fullColumn at section level to give the components full width
 */
import { ref, watch } from "vue";
import TextInput from "../../molecules/Inputs/Component/Component.vue";
import TextInputWithAddon from "../../molecules/Inputs/WithAddon/WithAddon.vue";
import TextArea from "../../molecules/Textareas/Simple/Simple.vue";
import Text from "../../atoms/Text/Text.vue";
import Select from "../../molecules/Selects/SimpleNative/SimpleNative.vue";
import SimpleRadioGroup from "../../molecules/RadioGroup/Simple/Simple.vue";
import CheckboxList from "../../molecules/Checkboxes/SimpleList/SimpleList.vue";
import MultiSelect from "../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue";
import CheckboxListWithInput from "../../molecules/Checkboxes/ListWithInput/ListWithInput.vue";
import InputWithButton from "../../molecules/Inputs/InputWithLeadingIconAndTrailingButton/InputWithLeadingIconAndTrailingButton.vue";
import Slider from "../../molecules/RangeSlider/RangeSlider.vue";
import Dropdown from "../../molecules/Selects/Component/Component.vue";
import AutoComplete from "../../molecules/AutoComplete/AutoComplete";
import TableWithInput from "../Tables/WithInput/WithInput.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { json } from "overmind";
import { lowerFirst, upperFirst, get } from "lodash";
export default {
    components: {
        TextInput,
        TextArea,
        Select,
        Text,
        SimpleRadioGroup,
        CheckboxList,
        MultiSelect,
        InputWithButton,
        CheckboxListWithInput,
        Slider,
        Dropdown,
        AutoComplete,
        TableWithInput,
        TextInputWithAddon,
    },
    emits: ["onValidate"],
    props: {
        id: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: 0,
        },
        /**
         * title of form
         */
        title: {
            type: String,
            default: "",
        },
        /**
         * description text to show below title
         */
        description: {
            type: String,
            default: "",
        },
        /**
         * list data with fields array to be displayed in form
         */
        list: {
            type: Array,
            default: () => [],
        },
        /**
         * to toggle orientation of simple radio group view
         */
        showInlineRadioGroup: {
            type: Boolean,
            default: false,
        },
        /**
         * options array for boolean select dropdown
         */
        booleanSelectOptions: {
            type: Array,
            default: () => [
                { value: true, text: "True" },
                { value: false, text: "False" },
            ],
        },
        showMandatoryLabel: {
            type: Boolean,
            default: true,
        },
        modelKey: {
            type: String,
            default: "id",
        },
        //Styling
        titleTextSize: {
            type: String,
            default: "3xl",
        },
        titleTextColor: {
            type: String,
            default: "graytext",
        },
        containerSpacing: {
            type: String,
            default: "space-y-8",
        },
        bodySpacing: {
            type: String,
            default: "space-y-6",
        },
        fieldSpacingClass: {
            type: String,
            default: "pt-6 px-2",
        },
        origFormData: {
            type: Object,
            default: () => {},
        },
        formData: {
            type: Object,
            default: () => {},
        },
        isHelp: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isFocusDescription: true,
            isFocusName: true,
            json,
            get,
            FIELD_TYPES: {
                TEXT: "TEXT",
                TEXT_AREA: "TEXTAREA",
                RADIO: "RADIO",
                CHECKBOX: "CHECKBOX",
                SELECT: "SELECT",
                BOOLEAN: "BOOLEAN",
                BOOLEAN_SELECT: "BOOLEAN_SELECT",
                TEXT_WITH_BUTTON: "TEXT_WITH_BUTTON",
                TAGS: "TAGS",
                TAGS_WITH_ADDON: "TAGS_WITH_ADDON",
                LOCATION_TAGS: "LOCATION_TAGS",
                CHECKBOX_WITH_INPUT: "CHECKBOX_WITH_INPUT",
                RANGE_SLIDER: "RANGE_SLIDER",
                DROPDOWN: "DROPDOWN",
                DROPDOWN_WITH_ADDON: "DROPDOWN_WITH_ADDON",
                AUTOCOMPLETE: "AUTOCOMPLETE",
                TABLE_WITH_INPUT: "TABLE_WITH_INPUT",
                HEADER: "HEADER",
                DATE: "DATE",
                TEXT_WITH_ADDON: "TEXT_WITH_ADDON",
            },
            type: null,
        };
    },
    watch: {
        formObject: {
            handler(val) {
                console.log(val, "val");
                if (val?.type?.name) {
                    this.type = val?.type?.name;
                }
            },
            deep: true,
        },
        formData: {
            handler(val) {
                console.log(val, "formData");
            },
            deep: true,
        },
    },
    methods: {
        getFiltered() {
            const items = [...JSON.parse(JSON.stringify(this.list))];
            console.log(items, "items");
            if (
                items?.[0]?.fields?.find(
                    (e) => e?.id === "24" && e?.modelField === "employeeCount"
                )
            ) {
                console.log("-=-=-=-=-", this.type);
                const list = [...items];
                const fields = this.list?.[0]?.fields;
                const types = ["BUSINESSES", "ORGANIZATIONS", "GOVERNMENTS"];
                if (types?.find((t) => t === this.type)) {
                    list[0].fields = [
                        ...fields?.slice(0, 4),
                        ...fields?.slice(11, 15),
                        fields[10],
                    ];
                } else if (this.type === "INDIVIDUALS") {
                    list[0].fields = fields?.slice(0, 11);
                } else if (this.type === "EMPLOYEES") {
                    list[0].fields = [
                        ...fields?.slice(0, 11),
                        ...fields?.slice(22, 26),
                    ];
                } else {
                    list[0].fields = fields?.slice(0, 4);
                }
                return list;
            } else if (
                this.formData?.targetCustomer?.targetCustomerUserType?.name ===
                    "EMPLOYEES" &&
                this.index === 3
            ) {
                const list = [...items];
                return list;
            } else if (
                this.formData?.targetCustomer?.targetCustomerUserType?.name ===
                    "INDIVIDUALS" &&
                this.index === 3
            ) {
                const list = [...items];
                return list.splice(0, 6);
            }
            return this.list;
        },
        getDescription(description) {
            if (description?.includes("{targetCustomer.name}")) {
                return description.replace(
                    "{targetCustomer.name}",
                    `<strong>${this.formData?.targetCustomer?.name}</strong>`
                );
            } else if (
                description?.includes("{targetCustomerUser.researchPrompt}")
            ) {
                return description.replace(
                    "{targetCustomerUser.researchPrompt}",
                    `<strong>${this.formData?.reasonSecond?.label}</strong>`
                );
            } else {
                return description;
            }
        },
        getSolutions() {
            let content = [];
            this.formObject.solutions?.map((solution, index) => {
                if (
                    index === this.formObject.solutions?.length - 1 &&
                    this.formObject.solutions?.length > 1
                ) {
                    content.push(`and/or ${lowerFirst(solution?.label)}`);
                } else {
                    content.push(lowerFirst(solution?.label));
                }
            });
            return content.join(", ");
        },
        getHtml() {
            if (this.isHelp) {
                // const problems = [];
                // this?.formObject?.problem?.map(p => problems?.push(p?.name));
                return `${upperFirst(
                    this.formObject.targetCustomer?.name || ""
                )} ${
                    this.formObject.motivation?.name
                        ? `${this.formObject.motivation?.name} `
                        : ""
                }${
                    this.formObject.action?.label
                        ? `${this.formObject.action?.label}`
                        : ""
                }${
                    this?.formObject?.problem
                        ? `, but ${this?.formObject?.problem?.name}`
                        : ""
                } ${
                    this.formObject.problemContext
                        ? ` because ${this.formObject.problemContext || ""}`
                        : ""
                }.`;
            }
            if (this.id === "2") {
                return `I want to help <b>${this.formObject.help}</b> by creating <b>${this.formData?.description}</b>.`;
            } else if (this.id === "4") {
                return `It's too difficult for ${
                    this.formData?.targetCustomer?.name
                } to ${this.formObject.reasonSecond?.label} because ${
                    this.formObject?.reasonFour || ""
                }.`;
            } else if (this.id === "5") {
                return `To ${this.formData?.reasonSecond?.label}, some ${
                    this.formData?.targetCustomer?.name
                } might ${this.getSolutions()}`;
            } else {
                return `I'm thinking about creating <span class="font-bold">${
                    this.formObject.description
                }</span>. ${
                    this.formObject.name
                        ? `For now, I'm going to call it "<span class="font-semibold">${this.formObject.name}.</span>"`
                        : ""
                }`;
            }
        },
        onClickAddress(site) {
            this.site = site;
            this.formated_address = site.formatted_address;
            setTimeout(() => this.actions.google.emptyPlaceResults(), 1500);
        },
        async searchGooglePlaces(val) {
            try {
                if (val) {
                    await this.actions.google.searchGooglePlaces({
                        keyword: val,
                        types: ["political"],
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
        getFieldLabel(label) {
            if (
                label ===
                "We are learning about the real experiences of {name} and how they..."
            ) {
                return label.replace(
                    "{name}",
                    this?.formObject?.["name"]?.toLowerCase() || ""
                );
            }
            if (label.includes("{name}"))
                return label.replace(
                    "{name}",
                    this?.formObject?.["name"] || ""
                );
            return label;
        },
    },
    setup(props, { emit }) {
        const formObject = ref({});
        const booleanObject = ref({});
        const rules = {};
        if (props.list.length > 0) {
            props.list.forEach((element) => {
                element.fields.forEach((item) => {
                    let fieldRules = {};
                    if (item.isRequired) {
                        rules[item[props.modelKey]] = { required };
                        fieldRules = { required };
                        formObject.value[item[props.modelKey]] = null;
                    }
                    if (item.minLength) {
                        fieldRules = {
                            ...fieldRules,
                            minLength: minLength(item.minLength),
                        };
                    }
                    if (item.maxLength) {
                        fieldRules = {
                            ...fieldRules,
                            maxLength: maxLength(item.maxLength),
                        };
                    }
                    if (Object.keys(fieldRules).length > 0) {
                        rules[item[props.modelKey]] = fieldRules;
                    }
                });
            });
        }
        if (Object.keys(props.origFormData).length > 0) {
            formObject.value = Object.assign({}, props.origFormData);
        }
        console.log(formObject.value, "formObject");
        const v$ = useVuelidate(rules, formObject.value);
        const getFormObject = () => {
            return formObject.value;
        };
        const selectionChanged = (id) => {
            formObject.value[id] = booleanObject.value[id] === "true";
        };
        const addOnBtnClicked = () => {
            console.log("button clicked");
        };

        const saveTableItem = (items, options, field) => {
            // const items = JSON.parse(options);
            // items.push(item);
            // console.log(items, 'items')
            formObject.value[field] = items;
        };

        const removeTableItem = (items, options, field) => {
            // const items = JSON.parse(options);
            // const index = items.find((i) => i.label === item?.label);
            // if (index > -1) {
            //   items.splice(index, 1);
            // }
            formObject.value[field] = items;
        };

        watch(formObject.value, async (newform, oldForm) => {
            v$.value.$touch();
            emit("onValidate", v$.value.$invalid);
            // console.log(v$.value.$errors);
        });

        const checkValidForm = () => {
            v$.value.$touch();
            return v$.value.$invalid;
        };

        return {
            formObject,
            getFormObject,
            selectionChanged,
            booleanObject,
            addOnBtnClicked,
            saveTableItem,
            removeTableItem,
            checkValidForm,
            v$,
        };
    },
};
</script>
