<template>
    <Template
        v-bind="{
            ...c.ventureOpportunities,
            items: opportunities,
            toggleSlideover: () => (isShow = true),
            isLoading,
            onClickItem: (item) =>
                $router.push({
                    name: 'Venture Opportunity Detail',
                    params: { ventureId: venture?.id, id: item?.id },
                }),
        }"
    />
    <WithNoSwiper
        v-bind="{
            isShow: isShow,
            isSaving: isSaving,
            list: form,
            modelKey: 'modelField',
            title: 'Add an Opportunity',
            content:
                'Opportunities are things that people do and sometimes have problems doing.',
            isHelp: true,
        }"
        :saveForm="saveForm"
        @onClose="
            () => {
                isShow = false;
            }
        "
    ></WithNoSwiper>
</template>

<script>
import Template from "@/components/templates/VentureOpportunities/VentureOpportunities.vue";
import { c } from "@/components/constants";
import { opportunityForm } from "../../.storybook/sampleData";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import { upperFirst } from "lodash";
import numeral from "numeral";
export default {
    components: {
        Template,
        WithNoSwiper,
    },
    data() {
        return {
            c,
            venture: null,
            opportunities: [],
            form: opportunityForm,
            isShow: false,
            isSaving: false,
            surveyData: [],
            isLoading: false,
        };
    },
    methods: {
        async onInitialize() {
            try {
                this.opportunities = [];
                const opportunities = this.venture.opportunities
                    ?.filter((o) => o?.isArchived !== true)
                    ?.sort((a, b) =>
                        a?.interviews?.filter((o) => o?.isArchived != true)
                            ?.length >
                        b?.interviews?.filter((o) => o?.isArchived != true)
                            ?.length
                            ? -1
                            : 1
                    );

                console.log(opportunities, "oportunities");
                const userSurveys =
                    await this.actions.userSurvey.getUserSurveys({
                        where: {
                            isArchived_not: true,
                        },
                        getValues: true,
                        all: true,
                        fragments: `
                            {id opportunity {id}}
                        `,
                    });
                const data = [];
                for (let i = 0; i < opportunities?.length; i++) {
                    const o = opportunities[i];
                    const surveyData = userSurveys?.filter(
                        (u) => u?.opportunity?.id === o?.id
                    );
                    const subtitles = [
                        {
                            icon: "MapIcon",
                            name: upperFirst(o?.targetCustomerUser?.name || ""),
                        },
                    ];
                    if (
                        (o?.childOpportunityLinks?.length || 0) +
                            (o?.parentOpportunityLinks?.length || 0) >
                        0
                    ) {
                        subtitles.push({
                            icon: "LinkIcon",
                            name: "Linked Opps",
                            count:
                                (o?.childOpportunityLinks?.length || 0) +
                                (o?.parentOpportunityLinks?.length || 0),
                        });
                    }
                    if (
                        o?.interviews?.filter((o) => !o?.isArchived)?.length > 0
                    ) {
                        subtitles.push({
                            icon: "ChatIcon",
                            name: "Interview",
                            count:
                                o?.interviews?.filter((o) => !o?.isArchived)
                                    ?.length || 0,
                        });
                    }
                    if (surveyData?.length > 0) {
                        subtitles.push({
                            icon: "TrendingUpIcon",
                            name: "Responses",
                            count: surveyData?.length,
                        });
                    }
                    let surveyParam = {};
                    if (surveyData?.length > 0) {
                        // if (o?.magnitudeScore)
                        surveyParam["Magnitude"] = {
                            out: o?.outOfMarketPercentage || 0,
                            low: o?.lowMarketPercentage || 0,
                            mid: o?.midMarketPercentage || 0,
                            hi: o?.hiMarketPercentage || 0,
                            magnitude: o?.magnitudeScore || 0,
                        };
                        // if (o?.actionDonePercentage > 0)
                        surveyParam["Action Done"] = `${
                            o?.actionDonePercentage || 0
                        }%`;
                        // if (o?.avgFrequency && o?.frequencyUnit)
                        surveyParam["Frequency"] = `${o?.avgFrequency || ""} ${
                            c.frequencyOptions?.find(
                                (f) => f?.value === o?.frequencyUnit
                            )?.name || ""
                        }`;
                        // if (o?.desireScore)
                        surveyParam["Desire"] = o?.desireScore || 0;
                        // if (o?.experience)
                        surveyParam["Experience"] = o?.experience || 0;
                        // if (o?.adjustedMarket)
                        surveyParam["Market"] = numeral(
                            o?.adjustedMarket || 0
                        ).format("0,0");
                    } else {
                        surveyParam = null;
                    }
                    data.push({
                        title: o.name,
                        actionLabel: o?.type,
                        id: o?.id,
                        detail: o,
                        subtitles,
                        survey: surveyParam,
                        customClass: "",
                    });
                }
                this.opportunities = data.sort((a, b) =>
                    a?.detail?.magnitudeScore > b?.detail?.magnitudeScore
                        ? -1
                        : 1
                );

                console.log(this.opportunities, "this.opportunities");
            } catch (e) {
                console.log(e);
            }
        },
        async saveForm(data) {
            if (this.isSaving) return false;
            try {
                this.isSaving = true;
                const opportunityParams = {
                    problemContext: data?.problemContext,
                    type: "ACTIVITY",
                    targetCustomerUser: {
                        connect: { id: data?.targetCustomer?.id },
                    },
                    desireText: data?.motivation?.value,
                    problems: {
                        connect: [],
                        create: [],
                    },
                    name: "",
                    venture: { connect: { id: this.$route.params?.ventureId } },
                };
                // data?.problem?.map((problem) => {
                if (!data?.problem?.id && data?.problem?.name) {
                    opportunityParams.problems.create.push({
                        type: "PROBLEM",
                        economy: {
                            create: {
                                name: data?.problem?.name,
                                problem: data?.problem?.name,
                            },
                        },
                    });
                } else if (data?.problem?.id) {
                    opportunityParams.problems.create.push({
                        type: "PROBLEM",
                        economy: { connect: { id: data?.problem?.id } },
                    });
                }
                // });
                if (data?.action?.id) {
                    opportunityParams.actionStep = {
                        connect: { id: data?.action?.id },
                    };
                } else if (data?.action?.label) {
                    opportunityParams.actionStep = {
                        create: {
                            name: data?.action?.label,
                            type: "ACTION",
                            status: "NEW",
                        },
                    };
                }
                const { saveOpportunity } =
                    await this.actions.opportunity.saveOpportunity({
                        data: opportunityParams,
                    });
                await this.actions.venture.saveVenture({
                    where: { id: this.$route.params?.ventureId },
                    data: {
                        opportunities: {
                            connect: [{ id: saveOpportunity?.id }],
                        },
                    },
                });
                const ventures = await this.actions.venture.getVentures({
                    where: { id: this.$route.params?.ventureId },
                    getValues: true,
                    all: true,
                    query: "ventureOpportunities",
                });
                this.venture = ventures[0];
                this.onInitialize();
                const venture = await this.actions.venture.getVentureById(
                    this.$route.params?.ventureId
                );
                let surveyData = [];
                for (
                    let i = 0;
                    i <
                    venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                        ?.length;
                    i++
                ) {
                    const targetCustomer = venture.targetCustomerUsers?.filter(
                        (t) => !t?.isArchived
                    )[i];
                    const data = await this.actions.userSurvey.getUserSurveys({
                        where: {
                            isArchived_not: true,
                            survey: {
                                targetCustomerUser: { id: targetCustomer?.id },
                            },
                        },
                        getValues: true,
                    });
                    console.log(data, "data");
                    surveyData = [...surveyData, ...data];
                }
                venture.surveyData = surveyData;
                this.actions.setCurrentVenture(venture);
                this.isShow = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.isSaving = false;
            }
        },
    },
    async created() {
        this.isLoading = true
        try {
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.ventureId },
                getValues: true,
                all: true,
                query: "ventureOpportunities",
            });
            this.venture = ventures[0];
            // this.actions.setCurrentVenture(this.venture);
            this.onInitialize();
            await this.actions.actionStep.getActionSteps({ all: true });
            await this.actions.economy.getEconomies({ all: true });
            const actionSteps = [];
            this.state.actionStep.actionSteps
                ?.filter((a) => a?.status === "APPROVED")
                .map((o) => actionSteps?.push({ ...o, label: o?.name }));
            const economies = [];
            this.state.economy.economies.map((o) =>
                economies?.push({ ...o, name: o?.problem, label: o?.problem })
            );
            this.form[0].fields[1].options = JSON.stringify(
                this.venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
            );
            this.form[0].fields[3].options = JSON.stringify(actionSteps);
            this.form[0].fields[5].options = JSON.stringify(
                economies?.filter((e) => e?.name)
            );
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    },
};
</script>

<style scoped></style>
