
<template>
  <div>
    <Text v-if="label" weight="semibold" size="sm" color="gray-700" custom-class="block" :content="label" />
    <div class="mt-1 flex rounded-md border">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <div class="
                absolute
                inset-y-0
                left-0
                pl-3
                flex
                items-center
                pointer-events-none
              ">
          <Icon :name="leadIcon" color="gray-400" hoverTextColor="gray-500" size="5" />
        </div>
        <input :type="type" v-model="inputValue" autocomplete="off" :placeholder="placeholder" :name="name" :id="id"
          v-on:keyup.enter="btnClick" class="
                focus:outline-none focus:ring-1 focus:ring-primary-500
                block
                w-full
                rounded-none rounded-l-md
                sm:text-sm
                border-gray-500
              " :class="`${customClass} ${leadIcon ? 'pl-10' : 'pl-3'}`" />
      </div>
      <button type="button" @click="btnClick" class="
          -ml-px
          relative
          inline-flex
          items-center
          space-x-2
          px-4
          py-2
          border border-indigo-700
          text-sm
          font-medium
          rounded-r-md
          text-white
          bg-indigo-700
          hover:bg-indigo-600
          focus:outline-none
          focus:ring-1
          focus:ring-indigo-500
          focus:border-indigo-500
            ">
        <Icon v-if="btnIcon" :name="btnIcon" color="white" hoverTextColor="white" size="5" />
        <span>{{ btnText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons";
import Text from "../../../atoms/Text/Text.vue";

/**
 * - Use when needs to show input form with leading icon and action button
 * - leadIcon, btnText, btnIcon are required.
 */
export default {
  components: {
    Icon,
    Text
  },
  props: {
    /**
   * Use to identify the input form
   */
    id: {
      type: String,
      default: "",
    },
    /**
     * Use to identify the input form. same with id. but it could be a special word or something like that
     */
    name: {
      type: String,
      default: "",
    },
    /**
     * Use to specify input type. ex: text, email, number. default will be text.
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Use to show what input form is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of input form
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to input form
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of input form
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Use to show leading icon. required.
     */
    leadIcon: {
      type: String,
      default: null
    },
    /**
     * Use to show button icon. required.
     */
    btnIcon: {
      type: String,
      default: "",
    },
    /**
     * Use to show button label. required.
     */
    btnText: {
      type: String,
      default: "",
      required: true,
    },
    btnClick: {
      type: Function,
      default: () => { }
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
